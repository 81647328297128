import React from 'react'

export default function footer() {
    return (
        <footer className="footer py-4" >
            <div className="container">
                <div className="row">
                    <div className="col-10 mx-auto col-md-10 text-white text-center text-capitalize align-items-center">
                        <p className="">Copyright &copy; {new Date().getFullYear().toString()} SH-Måleri AB</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
