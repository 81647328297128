import React from "react"
import { Link } from "gatsby"

function LanguageSelector({ lang, location, className }) {
  return (
    lang === "fi" ? 
    (
      <Link className={className} to={location.pathname.includes("/fi/") | location.pathname === "/fi" ? (location.pathname) : (`/fi/${location.pathname}`) }>
        fi
      </Link>
    ) 
    : 
    (
      <Link className={className} to={location.pathname.replace("/fi", "")}>
        sv
      </Link>
    )
  )  
}
export default LanguageSelector