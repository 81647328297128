import React, { Component } from "react"
import { Link } from "gatsby"
import LanguageSelector from "../components/languageselector"
import { FaHome } from "react-icons/fa"

export default class header extends Component {
  state = {
    navbarOpen: false,
    css: "collapse navbar-collapse",
    links: [
      {
        id: 1,
        path: "/",
        text: "sv",
      },
      {
        id: 2,
        path: "/fi",
        text: "fi",
      },
    ],
  }

  navbarHandler = () => {
    this.state.navbarOpen
      ? this.setState({
          navbarOpen: false,
          css: "collapse navbar-collapse",
        })
      : this.setState({
          navbarOpen: true,
          css: "collapse navbar-collapse show",
        })
  }

  render() {
    return (
      <nav className="navbar sticky-top navbar-expand-sm navbar-dark">
        <Link to={this.props.lang === "fi" ? ("/fi/") : ("/") } className="navbar-brand">
          <FaHome size="20px"/>
        </Link> 
        <button
          className="navbar-toggler"
          type="button"
          onClick={this.navbarHandler}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className={this.state.css}>
          <ul className="navbar-nav mx-auto">
            {this.state.links.map(link => {
              return (
                <li key={link.id} className="nav-item">
                  <LanguageSelector
                    className="nav-link"
                    location={this.props.location}
                    lang={link.text}
                  />
                </li>
              )
            })}
            <li className="li.nav-item.ml-sm-5"></li>
          </ul>
        </div>
      </nav>
    )
  }
}
