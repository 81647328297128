import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

export default function topsection({ img,styleClass,title,children,lang }) {
    return (
        <BackgroundImage fluid={img}>
            <div className={styleClass}>
                {lang === "fi" ? (
                    <Link className="text-decoration-none bg-transparent" to="/fi">        
                    <h1 className="title text-white text-center display-1 font-weight-bolder">
                        {title}
                    </h1>
                    </Link>)
                    :(
                    <Link className="text-decoration-none bg-transparent" to="/">        
                    <h1 className="title text-white text-center display-1 font-weight-bolder">
                        {title}
                    </h1>
                    </Link>
                )}
            </div>
            {children}
        </BackgroundImage>
    )
}
topsection.defaultProps = {
    title:"SH-Måleri AB",
    styleClass:"default-background"
}