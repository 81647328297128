/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "../styles/bootstrap.min.css"
import "./layout.scss"
import PropTypes from "prop-types"
import Header from "./header.js"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

const gdprBanner = {
  buttonText: "Godkänn",
  buttonTextFI: "Hyväksy",
  buttonTextDeny: "Avstå",
  buttonTextDenyFI: "Hylkää",
  bannerText:
    "Denna sida använder cookies for att förbättra användarupplevelsen. Läs mera om vår dataskyddspolicy ",
  bannerTextFI:
    "Tämä sivusto käyttää evästeitä parantamaan loppukäyttäjäkokemusta. Lue lisää meidän käyttäjätietokäytännöistämme ",
}

const Layout = ({ children, location, lang }) => (
  <>
    <Header location={location} lang={lang} />
    {children}
    <Footer />
    <CookieConsent
      disableStyles={true}
      enableDeclineButton={true}
      location="bottom"
      buttonText={
        lang === "fi" ? gdprBanner.buttonTextFI : gdprBanner.buttonText
      }
      declineButtonText={
        lang === "fi" ? gdprBanner.buttonTextDenyFI : gdprBanner.buttonTextDeny
      }
      cookieName="gatsby-gdpr-google-analytics"
      buttonClasses="btn btn-primary m-1"
      declineButtonClasses="btn btn-dark m-1"
      containerClasses="alert alert-info text-center position-sticky pb-4"
      contentClasses="mx-auto px-auto p-4 lead"
      flipButtons={true}
    >
      {lang === "fi" ? gdprBanner.bannerTextFI : gdprBanner.bannerText}
      {
        <Link to={lang === "fi" ? "/fi/dsp" : "/dsp"}>
          {lang === "fi" ? "tästä." : "här."}
        </Link>
      }
    </CookieConsent>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
